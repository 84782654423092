type Stage = 'dev' | 'prod';

const stage = (process.env.REACT_APP_STAGE as Stage) || 'dev';

const globalConfig = {
  commission: 0.005,
  supportEmail: 'support@monei.com',
  analyticsUrl:
    'https://eu-west-1.quicksight.aws.amazon.com/sn/start/analyses?directory_alias=monei-analytics',
  supportedLanguages: ['en', 'es', 'ca', 'pt', 'de', 'it', 'fr'],
  supportFormUrl: 'https://support.monei.com/hc/requests/new',
  termsOfServiceDoc: 'terms-of-service-partners-v1.pdf'
};

const stageConfig = {
  dev: {
    production: false,
    region: 'eu-west-1',
    graphqlEndpoint:
      'https://coeoe6nwznf23iffkkwxp72de4.appsync-api.eu-west-1.amazonaws.com/graphql',
    identityPoolId: 'eu-west-1:dfc71716-b3a8-46ec-8df3-bc7eb6999d11',
    userPoolId: 'eu-west-1_dqCMR7cXU',
    userPoolWebClientId: '6qmb8f8f2h8n5lsbo3gl3sv47b',
    serviceBucket: 'monei-dev-service-bucket',
    adminBucket: 'monei-dev-admin-bucket',
    paymentPagePreviewUrl: 'https://mo-js.microapps-staging.com/v2/payment-page/',
    hostedPaymentDomain: 'secure.microapps-staging.com',
    dashboardUrl: 'https://mo.microapps-staging.com',
    moneiApi: 'https://api.microapps-staging.com/v1',
    pibisiAccountId: 'efe79099-e3ee-43d4-a753-f207f9e41d62'
  },
  prod: {
    production: true,
    region: 'eu-west-1',
    graphqlEndpoint:
      'https://maoisnzcbfdfxehmcqhdl3othq.appsync-api.eu-west-1.amazonaws.com/graphql',
    identityPoolId: 'eu-west-1:583131ef-0993-496f-8bee-3e83f775d2bf',
    userPoolId: 'eu-west-1_zG8t1XwNT',
    userPoolWebClientId: '6j4l7oa6e9uouu3rlt2av8pofp',
    serviceBucket: 'monei-prod-service-bucket',
    adminBucket: 'monei-prod-admin-bucket',
    paymentPagePreviewUrl: 'https://js.monei.com/v2/payment-page/',
    hostedPaymentDomain: 'secure.monei.com',
    dashboardUrl: 'https://dashboard.monei.com',
    moneiApi: 'https://api.monei.com/v1',
    pibisiAccountId: 'efe79099-e3ee-43d4-a753-f207f9e41d62'
  }
};

export const config = {...globalConfig, ...stageConfig[stage]};
