import {LoadingOutlined} from '@ant-design/icons';
import {message, notification, Spin} from 'antd';
import moment from 'moment';
import 'moment/locale/en-gb';
import React from 'react';
import {setLocale} from 'yup';

moment.locale('en-gb');

setLocale({
  mixed: {
    defined: 'this field is required',
    required: 'this field is required',
    default: 'this field is required',
    oneOf: 'must be one of the following values: ${values}',
    notType: ({type}: any) => `must be a ${type}`
  },
  string: {
    email: 'must be a valid email',
    url: 'must be a valid URL',
    min: 'must be at least ${min} characters',
    max: 'must be at most ${max} characters',
    length: 'must be exactly ${length} characters',
    trim: 'must be a trimmed string',
    lowercase: 'must be a lowercase string',
    uppercase: 'must be a upper case string',
    matches: 'this value is not valid'
  },
  number: {
    min: 'must be greater than or equal to ${min}',
    max: 'must be less than or equal to ${max}',
    lessThan: 'must be less than ${less}',
    moreThan: 'must be greater than ${more}',
    positive: 'must be a positive number',
    negative: 'must be a negative number',
    integer: 'must be an integer'
  },
  date: {
    min: 'must be greater than or equal to ${min}',
    max: 'must be less than or equal to ${max}'
  },
  array: {
    min: 'must be greater than or equal to ${min}',
    max: 'must be less than or equal to ${max}'
  },
  boolean: {
    // @ts-ignore
    default: 'this field is required'
  }
});

Spin.setDefaultIndicator(<LoadingOutlined style={{fontSize: 32}} spin />);

notification.config({
  top: 70
});

message.config({
  top: 70
});
