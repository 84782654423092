import {Auth, Storage} from 'aws-amplify';
import 'global.config';
import React from 'react';
import ReactDOM from 'react-dom';
import {config} from 'stage.config';
import Root from './Root';
import * as serviceWorker from './serviceWorker';

Auth.configure({
  identityPoolId: config.identityPoolId,
  region: config.region,
  userPoolId: config.userPoolId,
  userPoolWebClientId: config.userPoolWebClientId
});

Storage.configure({
  AWSS3: {
    bucket: config.serviceBucket,
    region: config.region
  }
});

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
