import React from 'react';
import styled from 'styled-components';

interface IProps {
  disabled?: boolean;
  external?: boolean;
}

const Link = styled.a<IProps>`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const externalRegex = /^[a-zA-Z0-9+.-]+:\/\//;

export const AnchorLink: React.FC<IProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  href = '#',
  onClick,
  external,
  ...props
}) => {
  if (external || externalRegex.test(href)) {
    return (
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        aria-disabled={props.disabled}
        {...props}
      />
    );
  }
  return (
    <Link
      href={href}
      aria-disabled={props.disabled}
      {...props}
      onClick={(e) => {
        e.preventDefault();
        onClick?.(e);
      }}
    />
  );
};
