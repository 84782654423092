import {CognitoUser} from 'amazon-cognito-identity-js';
import {RcFile} from 'antd/lib/upload/interface';
import {FetchResult} from 'apollo-link';

export interface IUser extends CognitoUser {
  attributes: {
    'custom:account_id': string;
    'custom:tos_doc': string;
    'custom:tos_accepted_at': string;
    email: string;
    name?: string;
    email_verified: boolean;
    phone_number: string;
    phone_number_verified: boolean;

    sub: string;
  };
  [key: string]: any;
}

export interface IS3Object {
  bucket: string;
  region: string;
  key: string;
  mimeType: string;
  localUri: File | RcFile;
}

export type MutateFunction<Mutation, MutationVariables> = (
  variables: MutationVariables
) => Promise<FetchResult<Mutation>>;

export enum AuthState {
  signIn = 'signIn',
  signUp = 'signUp',
  signedIn = 'signedIn',
  confirmSignUp = 'confirmSignUp',
  confirmSignIn = 'confirmSignIn',
  forgotPassword = 'forgotPassword',
  forgotPasswordSubmit = 'forgotPasswordSubmit',
  requireNewPassword = 'requireNewPassword',
  init = 'init'
}

export enum MfaType {
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA'
}

export type IAuthState = {
  authState: AuthState;
  currentUser?: IUser;
  email?: string;
  code?: string;
  password?: string;
  mfaDestination?: string;
  mfaType?: MfaType;
  amazonCustomerId?: string;
  referralCode?: string;
  skipNewsletter?: boolean;
};

export interface IAuthQueryParams {
  email?: string;
  password?: string;
  code?: string;
  action?: string;
  amazonCustomerId?: string;
  status?: string;
}

export interface IAuthProps extends IAuthState {
  setState(state: IAuthState): void;
}

export enum EventType {
  CHARGE_PENDING = 'charge.pending',
  CHARGE_PENDING_PROCESSING = 'charge.pending_processing',
  CHARGE_UPDATED = 'charge.updated',
  CHARGE_AUTHORIZED = 'charge.authorized',
  CHARGE_REFUNDED = 'charge.refunded',
  CHARGE_PARTIALLY_REFUNDED = 'charge.partially_refunded',
  CHARGE_CAPTURED = 'charge.captured',
  CHARGE_CANCELED = 'charge.canceled',
  CHARGE_SUCCEEDED = 'charge.succeeded',
  CHARGE_FAILED = 'charge.failed',
  CHARGE_EXPIRED = 'charge.expired',
  CHARGE_CHARGEBACK = 'charge.chargeback',
  CHARGE_PAID_OUT = 'charge.paid_out',
  CAPTURE_PENDING = 'capture.pending',
  CAPTURE_SUCCEEDED = 'capture.succeeded',
  CAPTURE_FAILED = 'capture.failed',
  CHECKOUT_PENDING = 'checkout.pending',
  CHECKOUT_PENDING_PROCESSING = 'checkout.pending_processing',
  CHECKOUT_SUCCEEDED = 'checkout.succeeded',
  CHECKOUT_FAILED = 'checkout.failed',
  CHECKOUT_EXPIRED = 'checkout.expired',
  REFUND_PENDING = 'refund.pending',
  REFUND_SUCCEEDED = 'refund.succeeded',
  REFUND_FAILED = 'refund.failed',
  CANCEL_PENDING = 'cancel.pending',
  CANCEL_SUCCEEDED = 'cancel.succeeded',
  CANCEL_FAILED = 'cancel.failed',
  PENDING_PROCESSING = 'pending.processing',
  SETTLEMENT_COMPLETED = 'settlement.completed',
  SETTLEMENT_PENDING = 'settlement.pending',
  SETTLEMENT_SUSPENDED = 'settlement.suspended',
  ACCOUNT_PENDING = 'account.pending',
  ACCOUNT_BUSINESS_VERIFIED = 'account.business_verified',
  ACCOUNT_REPRESENTATIVE_VERIFIED = 'account.representative_verified',
  ACCOUNT_APPROVAL_REQUESTED = 'account.approval_requested',
  ACCOUNT_REVIEW_REQUESTED = 'account.review_requested',
  ACCOUNT_APPROVED = 'account.approved',
  ACCOUNT_REJECTED = 'account.rejected',
  ACCOUNT_SUSPENDED = 'account.suspended',
  ACCOUNT_ACTIVATED = 'account.activated',
  ACCOUNT_PAYOUTS_SUSPENDED = 'account.payouts_suspended',
  ACCOUNT_PAYOUTS_RESUMED = 'account.payouts_resumed',
  ACCOUNT_DATA_REQUESTED = 'account.data_requested',
  ACCOUNT_DELETED = 'account.deleted',
  ACCOUNT_DATA_UPDATED = 'account.data_updated',
  ACCOUNT_IBAN_UPDATED = 'account.iban_updated',
  ACCOUNT_PAYOUT_IBAN_UPDATED = 'account.payout_iban_updated',
  ACCOUNT_NOTE_UPDATED = 'account.note_updated',
  ACCOUNT_PLAN_UPDATED = 'account.plan_updated',
  ACCOUNT_BILLING_UPDATED = 'account.billing_updated',
  ACCOUNT_REVIEW_CONFIRMED = 'account.review_confirmed',
  ACCOUNT_CHURNED = 'account.churned',
  ACCOUNT_CHURN_REASON_UPDATED = 'account.churn_reason_updated',
  ACCOUNT_REACTIVATED = 'account.reactivated',
  ACCOUNT_BLOCKED = 'account.blocked',
  ACCOUNT_UNBLOCKED = 'account.unblocked',
  ACCOUNT_REFUNDS_ENABLED = 'account.refunds_enabled',
  ACCOUNT_REFUNDS_DISABLED = 'account.refunds_disabled',
  ACCOUNT_PAYOUT_TERMS_UPDATED = 'account.payout_terms_updated',
  ACCOUNT_TOKENIZATION_METHOD_ENABLED = 'account.tokenization_method_enabled',
  ACCOUNT_TOKENIZATION_METHOD_DISABLED = 'account.tokenization_method_disabled',
  ACCOUNT_RISK_PROFILE_UPDATED = 'account.risk_profile_updated',
  ACCOUNT_RISK_CHECK_COMPLETED = 'account.risk_check_completed',
  ACCOUNT_BANK_ACCOUNT_VERIFIED = 'account.bank_account_verified',
  ACCOUNT_PIBISI_VERIFIED = 'account.pibisi_verified',
  ACCOUNT_PIBISI_UNDER_REVIEW = 'account.pibisi_under_review',
  ACCOUNT_PIBISI_REJECTED = 'account.pibisi_rejected',
  ACCOUNT_TAGS_UPDATED = 'account.tags_updated',
  ACCOUNT_EXECUTIVE_ASSIGNED = 'account.executive_assigned',
  ACCOUNT_UPSELL = 'account.upsell',
  DOCUMENT_PENDING = 'document.pending',
  DOCUMENT_PROCESSING = 'document.processing',
  DOCUMENT_APPROVAL_REQUIRED = 'document.approval_required',
  DOCUMENT_APPROVED = 'document.approved',
  DOCUMENT_REJECTED = 'document.rejected',
  DOCUMENT_EXPIRED = 'document.expired',
  DOCUMENT_NOTE_UPDATED = 'document.note_updated',
  SUBSCRIPTION_PENDING = 'subscription.pending',
  SUBSCRIPTION_EXPIRED = 'subscription.expired',
  SUBSCRIPTION_TRIALING = 'subscription.trialing',
  SUBSCRIPTION_ACTIVATED = 'subscription.activated',
  SUBSCRIPTION_CANCELED = 'subscription.canceled',
  SUBSCRIPTION_PAUSED = 'subscription.paused',
  SUBSCRIPTION_PAST_DUE = 'subscription.past_due',
  SUBSCRIPTION_UPDATED = 'subscription.updated',
  PROVIDER_CREATED = 'provider.created',
  PROVIDER_UPDATED = 'provider.updated',
  PROVIDER_ENABLED = 'provider.enabled',
  PROVIDER_DISABLED = 'provider.disabled',
  ACCOUNT_INVOICE_PAID = 'account_invoice.paid',
  ACCOUNT_INVOICE_UNPAID = 'account_invoice.unpaid',
  ACCOUNT_INVOICE_PENDING = 'account_invoice.pending',
  ACCOUNT_INVOICE_PAST_DUE = 'account_invoice.past_due'
}

export enum AccountKeysUpdate {
  ACQUIRER = 'acquirer',
  ACTIVATEDAT = 'activatedAt',
  ADMINEMAIL = 'adminEmail',
  APPROVALREQUESTEDAT = 'approvalRequestedAt',
  APPROVEDAT = 'approvedAt',
  BILLINGPLAN = 'billingPlan',
  BRANDING = 'branding',
  BUSINESS_ADDRESS_CITY = 'business.address.city',
  BUSINESS_ADDRESS_COUNTRY = 'business.address.country',
  BUSINESS_ADDRESS_LINE1 = 'business.address.line1',
  BUSINESS_ADDRESS_LINE2 = 'business.address.line2',
  BUSINESS_ADDRESS_STATE = 'business.address.state',
  BUSINESS_ADDRESS_ZIP = 'business.address.zip',
  BUSINESS_CAPITAL = 'business.capital',
  BUSINESS_COMPANYNAME = 'business.companyName',
  BUSINESS_CONFIRMSERVICESDESCRIPTION = 'business.confirmServicesDescription',
  BUSINESS_CONFIRMWEBSITE = 'business.confirmWebsite',
  BUSINESS_COUNTRY = 'business.country',
  BUSINESS_CREATIONDATE = 'business.creationDate',
  BUSINESS_DOCUMENTNUMBER = 'business.documentNumber',
  BUSINESS_EMAIL = 'business.email',
  BUSINESS_INDUSTRY = 'business.industry',
  BUSINESS_LASTUPDATEDATE = 'business.lastUpdateDate',
  BUSINESS_LEGALADDRESS = 'business.legalAddress',
  BUSINESS_LEGALDESCRIPTION = 'business.legalDescription',
  BUSINESS_LEGALFORM = 'business.legalForm',
  BUSINESS_LEGALNAME = 'business.legalName',
  BUSINESS_MCC = 'business.mcc',
  BUSINESS_MONTHLYVOLUME = 'business.monthlyVolume',
  BUSINESS_PHONE = 'business.phone',
  BUSINESS_POSITIONS = 'business.positions',
  BUSINESS_PREVIOUSNAMES = 'business.previousNames',
  BUSINESS_REGISTRYRECORDS = 'business.registryRecords',
  BUSINESS_RISKLABELS = 'business.riskLabels',
  BUSINESS_SERVICESDESCRIPTION = 'business.servicesDescription',
  BUSINESS_STARTACTIVITYDATE = 'business.startActivityDate',
  BUSINESS_STATUS = 'business.status',
  BUSINESS_VERIFICATIONATTEMPTS = 'business.verificationAttempts',
  BUSINESS_WEBSITE = 'business.website',
  RISK_PROFILE = 'riskProfile',
  RISK_PROFILE_NOTE = 'riskProfileNote',
  RISK_IGNORED_KEYWORDS = 'riskIgnoredKeywords',
  CHURN_REASON = 'churnReason',
  CHURN_REASON_OPTION = 'churnReasonOption',
  CHURN_NOTE = 'churnNote',
  USER_NOTE = 'userNote',
  BLOCKED = 'blocked',
  STATUS_REASON = 'statusReason',
  STATUS_NOTE = 'statusNote',
  CONTRACT = 'contract',
  CREATEDAT = 'createdAt',
  CUSTOMDOMAIN = 'customDomain',
  DATAUPDATEDAT = 'dataUpdatedAt',
  DEFAULTCURRENCY = 'defaultCurrency',
  DEFAULTTRANSACTIONTYPE = 'defaultTransactionType',
  DELETEDAT = 'deletedAt',
  DYNAMICBILLING = 'dynamicBilling',
  FEES_DAILYEXTRASEATSFEE = 'fees.dailyExtraSeatsFee',
  FEES_DAILYPLATFORMFEE = 'fees.dailyPlatformFee',
  FEES_EXTRA = 'fees.extra',
  FEES_HIGHRISKFEE = 'fees.highRiskFee',
  FEES_STEPS = 'fees.steps',
  IBAN = 'iban',
  ID = 'id',
  NAME = 'name',
  NOTE = 'note',
  TAGS = 'tags',
  NOTIFICATIONS_EMAILCUSTOMERONREFUND = 'notifications.emailCustomerOnRefund',
  NOTIFICATIONS_EMAILCUSTOMERONSUCCESS = 'notifications.emailCustomerOnSuccess',
  NOTIFICATIONS_EMAILADMINONSUCCESS = 'notifications.emailAdminOnSuccess',
  PARTNER = 'partner',
  PAYOUTTERMS_FIRSTPERIOD = 'payoutTerms.firstPeriod',
  PAYOUTTERMS_IBAN = 'payoutTerms.iban',
  PAYOUTTERMS_PERIOD = 'payoutTerms.period',
  PAYOUTTERMS_RESERVEPERCENT = 'payoutTerms.reservePercent',
  PAYOUTTERMS_SUSPENDED = 'payoutTerms.suspended',
  PROMOCODE = 'promoCode',
  PUBLICBUSINESSDETAILS_ADDRESS_CITY = 'publicBusinessDetails.address.city',
  PUBLICBUSINESSDETAILS_ADDRESS_COUNTRY = 'publicBusinessDetails.address.country',
  PUBLICBUSINESSDETAILS_ADDRESS_LINE1 = 'publicBusinessDetails.address.line1',
  PUBLICBUSINESSDETAILS_ADDRESS_LINE2 = 'publicBusinessDetails.address.line2',
  PUBLICBUSINESSDETAILS_ADDRESS_STATE = 'publicBusinessDetails.address.state',
  PUBLICBUSINESSDETAILS_ADDRESS_ZIP = 'publicBusinessDetails.address.zip',
  PUBLICBUSINESSDETAILS_COMPANYNAME = 'publicBusinessDetails.companyName',
  PUBLICBUSINESSDETAILS_PHONE = 'publicBusinessDetails.phone',
  PUBLICBUSINESSDETAILS_SUPPORTPHONE = 'publicBusinessDetails.supportPhone',
  PUBLICBUSINESSDETAILS_SUPPORTWEBSITE = 'publicBusinessDetails.supportWebsite',
  PUBLICBUSINESSDETAILS_WEBSITE = 'publicBusinessDetails.website',
  REASON = 'reason',
  REFERRALCODE = 'referralCode',
  REJECTEDAT = 'rejectedAt',
  REPRESENTATIVES = 'representatives',
  RISKSCORE = 'riskScore',
  STATUS = 'status',
  SUSPENDEDAT = 'suspendedAt',
  TEST = 'test',
  TOKENIZATIONMETHODS_APPLEPAY_ENABLED = 'tokenizationMethods.applePay.enabled',
  TOKENIZATIONMETHODS_CLICKTOPAY = 'tokenizationMethods.clickToPay',
  TOKENIZATIONMETHODS_GOOGLEPAY_ENABLED = 'tokenizationMethods.googlePay.enabled',
  TRACEDETAILS_BROWSER = 'traceDetails.browser',
  TRACEDETAILS_BROWSERACCEPT = 'traceDetails.browserAccept',
  TRACEDETAILS_BROWSERVERSION = 'traceDetails.browserVersion',
  TRACEDETAILS_COUNTRYCODE = 'traceDetails.countryCode',
  TRACEDETAILS_DEVICEMODEL = 'traceDetails.deviceModel',
  TRACEDETAILS_DEVICETYPE = 'traceDetails.deviceType',
  TRACEDETAILS_IP = 'traceDetails.ip',
  TRACEDETAILS_LANG = 'traceDetails.lang',
  TRACEDETAILS_OS = 'traceDetails.os',
  TRACEDETAILS_OSVERSION = 'traceDetails.osVersion',
  TRACEDETAILS_SOURCE = 'traceDetails.source',
  TRACEDETAILS_SOURCEVERSION = 'traceDetails.sourceVersion',
  TRACEDETAILS_USERAGENT = 'traceDetails.userAgent',
  TRACEDETAILS_USEREMAIL = 'traceDetails.userEmail',
  TRACEDETAILS_USERID = 'traceDetails.userId',
  TRACEDETAILS_USERNAME = 'traceDetails.userName',
  UPDATEDAT = 'updatedAt',
  REVIEW_CONFIRMED = 'reviewConfirmed',
  SUBSCRIPTION_ACTIVATED = 'subscription.activated',
  SUBSCRIPTION_PENDING = 'subscription.pending',
  SUBSCRIPTION_CANCELED = 'subscription.canceled',
  SUBSCRIPTION_PAUSED = 'subscription.paused',
  SUBSCRIPTION_UPDATED = 'subscription.updated',
  SUBSCRIPTION_PAST_DUE = 'subscription.past_due',
  SUBSCRIPTION_TRIALING = 'subscription.trialing',
  ACCOUNT_EXECUTIVE_ASSIGNED = 'accountExecutive',
  ACCOUNT_UPSELL_EXECUTIVE = 'upsell.executive',
  ACCOUNT_UPSELL_PAYMENT_METHODS = 'upsell.paymentMethods',
  ACCOUNT_UPSELL_DATE = 'upsell.date'
}

export enum SubscriptionKeysUpdate {
  PAUSE_INTERVAL_COUNT = 'pauseIntervalCount',
  PAUSED_AT_PERIOD_END = 'pauseAtPeriodEnd',
  CANCELED_AT_PERIOD_END = 'cancelAtPeriodEnd',
  AMOUNT = 'amount',
  INTERVAL = 'interval',
  INTERVAL_COUNT = 'intervalcount',
  CUSTOMER_EMAIL = 'customer.email',
  CUSTOMER_NAME = 'customer.name',
  CUSTOMER_PHONE = 'customer.phone',
  BILLING_DETAILS_EMAIL = 'billingDetails.email',
  BILLING_DETAILS_NAME = 'billingDetails.name',
  BILLING_DETAILS_COMPANY = 'billingDetails.company',
  BILLING_DETAILS_PHONE = 'billingDetails.phone',
  BILLING_DETAILS_ADDRESS_CITY = 'billingDetails.address.city',
  BILLING_DETAILS_ADDRESS_COUNTRY = 'billingDetails.address.country',
  BILLING_DETAILS_ADDRESS_LINE1 = 'billingDetails.address.line1',
  BILLING_DETAILS_ADDRESS_LINE2 = 'billingDetails.address.line2',
  BILLING_DETAILS_ADDRESS_ZIP = 'billingDetails.address.zip',
  BILLING_DETAILS_ADDRESS_STATE = 'billingDetails.address.state',
  SHIPPING_DETAILS_EMAIL = 'shippingDetails.email',
  SHIPPING_DETAILS_NAME = 'shippingDetails.name',
  SHIPPING_DETAILS_COMPANY = 'shippingDetails.company',
  SHIPPING_DETAILS_PHONE = 'shippingDetails.phone',
  SHIPPING_DETAILS_ADDRESS_CITY = 'shippingDetails.address.city',
  SHIPPING_DETAILS_ADDRESS_COUNTRY = 'shippingDetails.address.country',
  SHIPPING_DETAILS_ADDRESS_LINE1 = 'shippingDetails.address.line1',
  SHIPPING_DETAILS_ADDRESS_LINE2 = 'shippingDetails.address.line2',
  SHIPPING_DETAILS_ADDRESS_ZIP = 'shippingDetails.address.zip',
  SHIPPING_DETAILS_ADDRESS_STATE = 'shippingDetails.address.state'
}

export enum acquirers {
  COMERCIA_GLOBAL_PAY = 'Comercia',
  CREDORAX = 'Finaro'
}

export enum paymentMethodsEnum {
  card = 'Credit Card',
  bizum = 'Bizum'
}

export type LabelValue = {value: string; label: string};

export type NonEmptyRecord<T> = {[P in keyof T]-?: NonEmptyRecord<NonNullable<T[P]>>};
