import Big from 'big.js';
import dot from 'dot-object';
import {CountryCode, parsePhoneNumberFromString} from 'libphonenumber-js';
import {divide, multiply, round} from 'mathjs';
import React, {ComponentType, LazyExoticComponent} from 'react';
import {ChargeFieldsFragment} from '../api/generated';
import {omitDeep} from './cleanTypenameLink';

export function enumKeys<E>(e: E): Array<keyof E> {
  return Object.keys(e) as Array<keyof E>;
}

export function enumValues<E>(e: E): Array<E> {
  return Object.values(e) as Array<E>;
}

/**
 * Calculates percentage rate
 * @param num
 * @param denom
 */
export const getRate = (num = 0, denom = 1) => {
  if (denom === 0) return 0;
  const ratio = num / denom;
  return (ratio > 1 ? 1 : ratio) * 100;
};

/**
 * Calculates increase in percentages
 * @param first
 * @param second
 */
export const getIncrease = (first = 0, second = 1) => {
  if (first === 0 && second === 0) return 0;
  if (second === 0) return 100;
  const ratio = (first - second) / second;
  if (ratio > 100) return 100;
  return ratio * 100;
};

export interface ILazyComponent<T extends ComponentType<any>> extends LazyExoticComponent<T> {
  preload: () => Promise<any>;
}

/**
 * Lazy load component and add preload method
 * Call Component.preload() to fetch async chunks
 * @param factory
 */
export const lazyWithPreload = <T extends ComponentType<any>>(
  factory: () => Promise<{default: T}>
) => {
  const Component = React.lazy(factory) as ILazyComponent<T>;
  Component.preload = factory;
  return Component;
};

/**
 * Get random int in rage
 * @param min
 * @param max
 */
export const getRandomInt = (min: number, max: number) => {
  return Math.round(Math.random() * (max - min) + min);
};

/**
 *intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > intersperse([1,2,3], 0)
 * [1,0,2,0,3]
 */
export function intersperse(arr: any[], sep: any) {
  if (arr.length === 0) return [];
  return arr.slice(1).reduce((xs, x) => xs.concat([sep, x]), [arr[0]]);
}

/**
 * Checks if variable is undefined or null
 * @param variable
 */
export const isUndefined = (variable: any) => {
  return typeof variable === 'undefined' || variable === null;
};

/**
 * Capitalize firs letter
 * @param s - string
 */
export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const downloadTextAsFile = ({
  fileName,
  text,
  type
}: {
  fileName: string;
  text: string;
  type: string;
}) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${type};charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadFile = (url: string, name?: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', name ?? 'true');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

/**
 * Returns zero if number is negative
 * @param n
 */
export const positiveOrZero = (n: number) => {
  return n > 0 ? n : 0;
};

/**
 * Makes sure that the object is of type object and is not null.
 */
export const isObject = (object: any) => object != null && typeof object === 'object';

export const pick = (obj: {[key: string]: any} | undefined, keys: string[]) => {
  const result: {[key: string]: any} = {};
  if (!obj) return result;
  keys.forEach((key) => {
    if (obj[key] === undefined) return;
    result[key] = obj[key];
  });
  return result;
};

export const omit = (obj: {[key: string]: any} | undefined, keys: string[]) => {
  const result: {[key: string]: any} = {};
  if (!obj) return result;
  Object.keys(obj).forEach((key) => {
    if (keys.includes(key)) return;
    result[key] = obj[key];
  });
  return result;
};

export const delay = (ms: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });

/**
 * Check if object is empty
 * @param o - object to test
 */
export function isEmpty(o?: object) {
  if (!o) return true;
  return Object.keys(o).length === 0;
}

export function nullify(o: any) {
  if (!isObject(o)) return o;
  return Object.keys(o).reduce((result, key) => {
    if (['', undefined].includes(o[key])) {
      result[key] = null;
    } else if (Array.isArray(o[key])) {
      result[key] = o[key].map(nullify);
    } else if (isObject(o[key])) {
      result[key] = nullify(o[key]);
    } else {
      result[key] = o[key];
    }
    return result;
  }, {} as any);
}

export const toCents = (amount: number | null = null) => {
  if (amount === null) return null;
  return round(multiply(amount, 100));
};

export const fromCents = (amount: number | null = null) => {
  if (amount === null) return null;
  return round(divide(amount, 100));
};

export const amountToInt = (amount: number) => {
  return Big(amount).times(100).toNumber();
};

export const amountFromInt = (amount: number) => {
  return Big(amount).div(100).toNumber();
};

export const unique = <T>(array: T[]) => {
  return Array.from(new Set(array));
};
export const randomHex = (size: number) => {
  return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
};
export const arrayIncludesSome = (array: any[], values: any[]) => {
  return values.some((value) => array.includes(value));
};

export const parseJSON = <T = Record<string, any>>(string: string) => {
  try {
    return JSON.parse(string) as T;
  } catch (error) {
    return undefined;
  }
};

export const isDefined = (
  value: any,
  options: {allowZero?: boolean; allowEmptyString?: boolean} = {
    allowZero: true,
    allowEmptyString: true
  }
) => {
  if (options.allowEmptyString && value === '') return true;
  if (options.allowZero && value === 0) return true;
  return Boolean(value);
};

export const isQrPayment = (charge: ChargeFieldsFragment) => {
  return !!charge.paymentMethod && charge.orderId?.startsWith('QR');
};

/**
 * Get changed keys from two objects in dot notation
 * @param obj1
 * @param obj2
 */
export const getChangedKeys = (obj1: any = {}, obj2: any = {}) => {
  const dotObj1 = dot.dot(obj1);
  const dotObj2 = dot.dot(obj2);
  const keys = unique(Object.keys(dotObj1).concat(Object.keys(dotObj2)));
  return keys.filter((key) => JSON.stringify(dotObj1[key]) !== JSON.stringify(dotObj2[key]));
};

export const dateFormat = {
  dateTime: 'DD/MM/YY HH:mm',
  localizedShort: 'lll'
};

export const truncate = (str: string | null | undefined, length: number) => {
  if (!str) return '';
  if (str.length <= length) return str;
  return `${str.substring(0, length)}...`;
};

export const normalizePhone = (phone?: string | null, countryCode?: string | null) => {
  if (!phone) return null;
  return parsePhoneNumberFromString(phone, countryCode as CountryCode)?.format('E.164');
};

export const openNewTab = (url: string | undefined) => url && window.open(url, '_blank');

export const prettifyEvent = (event: any) =>
  JSON.stringify(
    omitDeep(
      {
        ...event,
        changed: undefined
      },
      '__typename'
    ),
    null,
    2
  );

export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

export const sortObjectKeysByValue = (obj: Record<string, string>): Record<string, string> => {
  const sortedKeys = Object.keys(obj).sort((a, b) => obj[a].localeCompare(obj[b]));

  return sortedKeys.reduce((acc: Record<string, string>, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
};
