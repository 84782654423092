import {LockOutlined} from '@ant-design/icons/lib';
import {Button, Card} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import {Form, FormItem, Input, Checkbox} from 'formik-antd';
import React from 'react';
import {AuthState, IAuthProps, MfaType} from 'types';
import * as yup from 'yup';
import {AnchorLink} from '../AnchorLink';
import {Description, Extra} from './Shared';

interface IFormValues {
  code: string;
  remember: boolean;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({
  isSubmitting,
  setState,
  mfaDestination,
  email,
  password,
  mfaType,
  setSubmitting
}) => {
  const backToSignIn = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.signIn, email});
  };

  const resendCode = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitting(true);
    await Auth.signIn(email!, password);
    setSubmitting(false);
  };

  return (
    <Card
      title="Two-step authentication"
      extra={<AnchorLink onClick={backToSignIn}>Back to Sign In</AnchorLink>}>
      <Description>
        {mfaType === MfaType.SMS_MFA ? (
          <>
            To continue, please enter the 6-digit verification code sent to{' '}
            <strong>{mfaDestination}</strong>.
          </>
        ) : (
          <>
            To continue, please enter the 6-digit verification code generated by your{' '}
            <strong>authenticator app</strong>.
          </>
        )}
      </Description>
      <Form>
        <FormItem name="code">
          <Input
            name="code"
            type="text"
            size="large"
            autoFocus
            autoComplete="one-time-code"
            prefix={<LockOutlined />}
            placeholder="Verification code"
          />
        </FormItem>
        <p>
          <Checkbox name="remember">Remember this device?</Checkbox>
        </p>
        <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
          Confirm
        </Button>
        <Extra>
          Lost your code? <AnchorLink onClick={resendCode}>Resend Code</AnchorLink>
        </Extra>
      </Form>
    </Card>
  );
};

export const ConfirmSignIn = withFormik<IAuthProps, IFormValues>({
  validationSchema: () =>
    yup.object().shape({
      code: yup.string().required()
    }),
  mapPropsToValues: ({code = ''}) => ({code, remember: true}),
  handleSubmit: async ({code, remember}, {props, setErrors, setSubmitting}) => {
    try {
      await Auth.confirmSignIn(props.currentUser, code, props.mfaType);
      if (remember) await Auth.rememberDevice();
      const currentUser = await Auth.currentAuthenticatedUser();
      props.setState({authState: AuthState.signedIn, currentUser});
    } catch (error: any) {
      setErrors({code: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
