import {IdcardOutlined, LockOutlined} from '@ant-design/icons/lib';
import {Button, Card} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import {Form, FormItem, Input} from 'formik-antd';
import React from 'react';
import {AuthState, IAuthProps} from 'types';
import * as yup from 'yup';
import {AnchorLink} from '../AnchorLink';
import {Extra} from './Shared';

interface IFormValues {
  email: string;
  password: string;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({isSubmitting, setState, values}) => {
  const resetPassword = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.forgotPassword, email: values.email});
  };

  return (
    <Card>
      <Form>
        <FormItem name="email">
          <Input
            autoFocus
            name="email"
            type="email"
            size="large"
            prefix={<IdcardOutlined />}
            placeholder="Email"
          />
        </FormItem>
        <FormItem name="password">
          <Input.Password
            name="password"
            type="password"
            size="large"
            prefix={<LockOutlined />}
            placeholder="Password"
          />
        </FormItem>
        <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
          Sign In
        </Button>
        <Extra>
          Forgot your password? <AnchorLink onClick={resetPassword}>Reset password</AnchorLink>
        </Extra>
      </Form>
    </Card>
  );
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required()
});

export const SignInForm = withFormik<IAuthProps, IFormValues>({
  validationSchema: schema,
  mapPropsToValues: ({email = '', password = ''}) => ({email, password}),
  handleSubmit: async ({email, password}, {props, setErrors, setSubmitting}) => {
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName) {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          return props.setState({
            authState: AuthState.requireNewPassword,
            currentUser: user,
            email
          });
        }
        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          return props.setState({
            email,
            password,
            authState: AuthState.confirmSignIn,
            currentUser: user,
            mfaDestination: user.challengeParam?.CODE_DELIVERY_DESTINATION,
            mfaType: user.challengeName
          });
        }
      }
      // get current user, CognitoUser returned form Auth.signIn does not have attributes
      const currentUser = await Auth.currentAuthenticatedUser();
      props.setState({authState: AuthState.signedIn, currentUser, email});
    } catch (error: any) {
      // render confirm view if user is not confirmed
      if (error.code === 'UserNotConfirmedException') {
        return props.setState({authState: AuthState.confirmSignUp, email, password});
      }
      if (error.code === 'NotAuthorizedException') {
        return setErrors({password: error.message});
      }
      setErrors({password: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
