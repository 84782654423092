import React from 'react';
import {IAuthState} from 'types';

export interface IUserContext {
  userId: string;
  userEmail: string;
  userName?: string;
  userPhone?: string;
  accessLevel: string;
  termsOfServiceUrl?: string;
  termsOfServiceAcceptedAt?: string;
  setCurrentUser(user: any): void;
  onSignOut(state?: IAuthState): void;
  storeId?: string;
}

export const UserContext = React.createContext<IUserContext>({
  userId: '',
  userEmail: '',
  userName: '',
  accessLevel: '',
  setCurrentUser: () => {},
  onSignOut: () => {}
});
