/* tslint:disable:react-hooks-nesting */
import {message} from 'antd';
import {Auth} from 'aws-amplify';
import {ConfirmSignIn} from 'components/Auth/ConfirmSignIn';
import {SignInForm} from 'components/Auth/SignInForm';
import bg from 'images/bg.svg';
import {UserContext} from 'lib/userContext';
import {ILazyComponent} from 'lib/utils';
import qs from 'query-string';
import React, {Suspense, useEffect, useState} from 'react';
import styled from 'styled-components';
import {AuthState, IAuthProps, IAuthQueryParams, IAuthState, IUser} from 'types';
import {Spinner} from '../Spinner';
import {ConfirmSignUp} from './ConfirmSignUp';
import {ForgotPassword} from './ForgotPassword';
import {ForgotPasswordSubmit} from './ForgotPasswordSubmit';
import {RequireNewPassword} from './RequireNewPassword';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 100px;
  height: 100%;
  background: url(${bg}) no-repeat 0 0;
  background-size: 100%;
`;

const Content = styled.div`
  width: 380px;
  margin: 0 auto 50px;
  .ant-input-prefix {
    color: rgba(0, 0, 0, 0.25) !important;
  }
  .ant-card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  }
`;

const Logo = styled.img`
  width: 200px;
  height: 44px;
  margin-bottom: 30px;
  flex-shrink: 0;
`;

const initialState: IAuthState = {
  authState: AuthState.init
};

const allowedActions = [
  AuthState.signIn,
  AuthState.signUp,
  AuthState.forgotPassword,
  AuthState.confirmSignUp
];

export const withAuthenticator = (Cmp: ILazyComponent<any>) => {
  return () => {
    const [state, setState] = useState<IAuthState>(initialState);

    const setAuthState = (state: IAuthState) => {
      if (state.authState === AuthState.signedIn) {
        window.history.pushState('', '', window.location.pathname);
      }
      setState(state);
    };

    const handleSignOut = async (state: IAuthState = {authState: AuthState.signIn}) => {
      await Auth.signOut();
      setState(state);
      window.history.pushState('', '', '/');
    };

    useEffect(() => {
      const directAuthState = ({email, password, code, action}: IAuthQueryParams) => {
        setAuthState({authState: action as AuthState, email, password, code});
      };

      const directSignIn = async (email: string, password: string) => {
        try {
          const user = await Auth.signIn(email, password);
          if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return setAuthState({
              authState: AuthState.requireNewPassword,
              currentUser: user,
              email
            });
          }
          return await checkAuth();
        } catch (error: any) {
          if (error.code === 'UserNotConfirmedException') {
            return setAuthState({authState: AuthState.confirmSignUp, email, password});
          }
          message.error(error.message);
          setAuthState({authState: AuthState.signIn, email});
        }
      };

      const directConfirmation = async (email: string, code: string) => {
        try {
          await Auth.confirmSignUp(email, code);
          message.success('Your account is confirmed, please sign in');
        } catch (error: any) {
          message.error(error.message);
        } finally {
          setAuthState({authState: AuthState.signIn, email});
        }
      };

      const checkAuth = async () => {
        try {
          const currentUser = await Auth.currentAuthenticatedUser({bypassCache: true});
          setState({authState: AuthState.signedIn, currentUser});
        } catch {
          setAuthState({authState: AuthState.signIn});
        }
      };

      const {email, password, code, action} = qs.parse(window.location.search) as IAuthQueryParams;

      if (allowedActions.includes(action as AuthState)) {
        if (action === AuthState.signIn && email && password) {
          // noinspection JSIgnoredPromiseFromCall
          directSignIn(email, password);
        } else {
          directAuthState({email, password, code, action});
        }
      } else if (email && code) {
        // noinspection JSIgnoredPromiseFromCall
        directConfirmation(email, code);
      } else {
        // noinspection JSIgnoredPromiseFromCall
        checkAuth();
      }
      Cmp.preload().then(() => {
        console.log('App is loaded');
      });
    }, []);

    const renderAuthView = () => {
      const props: IAuthProps = {...state, setState: setAuthState};
      switch (state.authState) {
        case AuthState.confirmSignUp:
          return <ConfirmSignUp {...props} />;
        case AuthState.confirmSignIn:
          return <ConfirmSignIn {...props} />;
        case AuthState.forgotPassword:
          return <ForgotPassword {...props} />;
        case AuthState.forgotPasswordSubmit:
          return <ForgotPasswordSubmit {...props} />;
        case AuthState.requireNewPassword:
          return <RequireNewPassword {...props} />;
        default:
          return <SignInForm {...props} />;
      }
    };

    const getAccessLevel = (currentUser: IUser) => {
      const session = currentUser.getSignInUserSession()!;
      const {payload} = session.getIdToken();
      return payload['cognito:groups'][0];
    };

    if (state.authState === AuthState.init) return <Spinner />;
    if (state.authState === AuthState.signedIn) {
      const currentUser = state.currentUser!;
      return (
        <Suspense fallback={<Spinner />}>
          <UserContext.Provider
            value={{
              onSignOut: handleSignOut,
              setCurrentUser: (user) => setState((state) => ({...state, currentUser: user})),
              userEmail: currentUser.attributes.email,
              userName: currentUser.attributes.name,
              userPhone: currentUser.attributes.phone_number,
              userId: currentUser.attributes.sub,
              termsOfServiceUrl: currentUser.attributes['custom:tos_doc'],
              termsOfServiceAcceptedAt: currentUser.attributes['custom:tos_accepted_at'],
              accessLevel: getAccessLevel(currentUser)
            }}>
            <Cmp />
          </UserContext.Provider>
        </Suspense>
      );
    }
    return (
      <Container>
        <Logo src="https://assets.monei.com/images/logo.svg" />
        <h2>Admin Dashboard</h2>
        <Content>{renderAuthView()}</Content>
      </Container>
    );
  };
};
